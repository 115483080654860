<!--
 * @Author: CL
 * @Date: 2021-06-16 09:09:10
 * @LastEditTime: 2021-06-29 13:47:40
 * @Description: 文章标签组件
-->

<template>
  <div class="bloglabels-contain">
    <span>
      <IconFont type="time" :size="14" />
      <div class="text">{{ info.createTime }}</div>
    </span>
    <span>
      <IconFont type="views" :size="18" />
      <div class="text">{{ info.views }}</div>
    </span>
    <span>
      <IconFont type="commen" :size="16" />
      <div class="text">{{ info.comments }}</div>
    </span>
    <span>
      <IconFont type="like" :size="18" />
      <div class="text">{{ info.likes }}</div>
    </span>
    <span>
      <IconFont type="tag" :size="20" />
      <div class="text">
        {{ info.tags }}
        <!-- <span v-for="(item, index) in info.tags"></span> -->
      </div>
    </span>
  </div>
</template>

<script>
import IconFont from '@/components/IconFont';

export default {
  components: {
    IconFont
  },

  props: {
    info: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.bloglabels-contain{
  margin-top: 10px;
  font-size: 14px;
  color: @info;
  display: flex;

  > span {
    display: flex;
    align-items: center;
    margin-right: 25px;

    .text{
      margin-left: 5px;
    }

    &:nth-of-type(1){
      color: @success;
    }

    &:nth-of-type(2){
      color: @warning;
    }

    &:nth-of-type(3){
      color: @primary;
    }

    &:last-of-type{
      color: @danger;
    }
  }
}
</style>
