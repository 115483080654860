<!--
 * @Author: CL
 * @Date: 2021-06-15 09:42:54
 * @LastEditTime: 2021-08-03 23:44:01
 * @Description: 文章列表组件
-->

<template>
  <div class="bloglistwrap-contain" ref="contain" v-loading="loading">
    <div class="search-wrap">
      <el-input
        placeholder="请输入文章标题"
        @input="searchInput"
        v-model="articleTitle"
        clearable>
      </el-input>
    </div>

    <template v-if="blogList.length > 0">
      <div class="blog-item" v-for="item in blogList" :key="item.id">
        <router-link :to="{ name: 'detail', params: {id: item.id} }" class="blog-left">
          <img v-imglazy="baseURL + item.picture" :alt="item.title" :title="item.title">
        </router-link>
        <div class="blog-right">
          <router-link :to="{ name: 'detail', params: {id: item.id} }" class="blog-right-title">{{ item.title }}</router-link>
          <BlogLabels :info="item" />
        </div>
      </div>
    </template>

    <template v-else>
      <Empty />
    </template>

    <LoadingMore :isShow="isLoadingMore" :bottom="20" />
    <NoMore :isShow="hasMore" word="文章" />
  </div>
</template>

<script>
import { getBlog } from '@/api/blog.js';
import IconFont from '@/components/IconFont';
import { mapState } from 'vuex';
import BlogLabels from './BlogLabels.vue';
import LoadingMore from '@/components/LoadingMore';
import NoMore from '@/components/NoMore';
import Empty from '@/components/Empty';

export default {
  data(){
    return {
      page: 1,
      size: 10,
      total: 0,
      loading: true,   //是否在加载
      blogList: [],      //文章列表
      isLoadingMore: false,
      hasMore: false,

      articleTitle: '',  //文章标题
      timer: null
    }
  },

  methods: {
    /**
     * 请求文章数据
     */
    async queryBlog(){
      this.loading = true;
      try{
        const { page, size, articleTitle } = this;
        const res = await getBlog(page, size, { title: articleTitle });
        // const res = await getBlog(page, size);
        this.loading = false;
        if(res.code == 200){
          const { count, rows } = res.data;
          this.blogList = rows.map(item => {
            return {
              ...item,
              comments: this.getCommentsCount(item.Comments)
            }
          });
          this.total = count;
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     * 得到评论的总数量
     */
    getCommentsCount(arr){
      if(!arr) return 0;

      return arr.length;
    },

    async fetchMore(){
      if(this.total == this.blogList.length) {
        this.hasMore = true;
        return
      };
      this.isLoadingMore = true;
      try{
        this.page++;
        const res = await getBlog(this.page, this.size, { title: this.articleTitle });
        // const res = await getBlog(this.page, this.size);
        await this.$utils.delay(1500);
        this.isLoadingMore = false;
        if(res.code == 200){
          this.blogList = [...this.blogList, ...res.data.rows];
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     * 搜索文章
     */
    searchInput(){
      if(this.timer){
        return;
      }
      this.timer = setTimeout(() => {
        this.timer = null;
        clearTimeout(this.timer);
        this.queryBlog();
      }, 2000)
    }
  },

  created() {
    this.queryBlog();
  },

  mounted() {
    this.$bus.$on('fetchMore', this.$utils.deBounce(this.fetchMore, 50));
  },

  destroyed() {
    this.$bus.$off('fetchMore', this.$utils.deBounce(this.fetchMore, 50));
  },

  components: {
    IconFont,
    BlogLabels,
    LoadingMore,
    NoMore,
    Empty
  },

  computed: {
    ...mapState(['baseURL'])
  }
}
</script>

<style lang="less">
@import '~@/styles/var.less';

.bloglistwrap-contain{
  width: 100%;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .search-wrap{
    width: 80%;
    margin: 0 auto 15px;
    position: sticky;
    z-index: 997;
    top: 0px;
    // box-shadow: 0 0 5px 5px #ececec;
  }

  .blog-item{
    width: 80%;
    margin: 0 auto 30px;
    display: flex;
    border-bottom: 1px solid @borderBottom;
    padding: 20px 0;
    box-sizing: border-box;

    .blog-left > img{
      width: 150px;
      height: 100px;
      border-radius: 5px;
      cursor: pointer;
    }

    .blog-right{
      margin-left: 15px;

      &-title{
        display: inline-block;
        font-size: 18px;
        color: #5b6773;
        padding: 5px 0 10px 0;

        &:hover{
          cursor: pointer;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 4'%3E%3Cpath fill='none' stroke='%23599' d='M0 3.5c5 0 5-3 10-3s5 3 10 3 5-3 10-3 5 3 10 3'/%3E%3C/svg%3E") repeat-x 0 100%; 
          background-size: 20px auto;
          animation: move 1s infinite linear;
        }
      }
    }
  }

  .nomore-contain{
    padding-bottom: 30px;
  }

  .pager{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

//波浪线动画
@keyframes move{
  from { background-position: 0 100%; }
  to   { background-position: -20px 100%; }
}
</style>
