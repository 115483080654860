<!--
 * @Author: CL
 * @Date: 2021-06-03 14:38:33
 * @LastEditTime: 2021-08-03 17:23:57
 * @Description: 数据为空的展示组件
 * 1. image: 资源类型,同样可以上传自定义图片
 * 2. text: 描述性文字
 * 3. size: 图片大小, 宽高相同
-->

<template>
  <div class="empty-contain">
    <div class="empty-wrap">
      <img :src="image" :style="{ width: size + 'px', height: size + 'px' }">
      <div>{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '无数据'
    },

    image: {
      type: String,
      default: 'https://img01.yzcdn.cn/vant/custom-empty-image.png'
    },

    size: {
      type: Number,
      default: 100
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.empty-contain{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  .empty-wrap{
    div{
      text-align: center;
      color: @info;
      font-weight: bold;
      margin-top: 15px;
    }
  }
}
</style>