<!--
 * @Author: CL
 * @Date: 2021-06-21 13:19:59
 * @LastEditTime: 2021-07-04 22:45:46
 * @Description: 文章列表页
-->

<template>
  <div class="bloglist-contain" ref="bloglist" @scroll="handleScroll">
    <BlogList />
    <GoHome />
    <!-- <ToTop :isShow="isShow" /> -->
    <Rocket :isLaunch="isShow" :isLand="isLand" />
  </div>
</template>

<script>
import BlogList from './components/BlogList.vue';
import GoHome from '@/components/GoHome';
import ToTop from '@/components/ToTop';
import Rocket from '@/components/Rocket';

export default {
  data(){
    return {
      isShow: false,
      isLand: false
    }
  },

  methods: {
    handleScroll(){
      if(this.$refs.bloglist.scrollTop == 0){
        this.isShow = true;
        this.isLand = false;
      }else if(this.$refs.bloglist.scrollTop > 0){
        this.isLand = true;
      }

      this.$bus.$emit('mainScroll', this.$refs.bloglist);
      if(this.$refs.bloglist.scrollTop + this.$refs.bloglist.clientHeight >= this.$refs.bloglist.scrollHeight - 50){
        this.$bus.$emit('fetchMore');
      }
    },

    handleScrollTop(){
      this.$refs.bloglist.scrollTop = 0;
      setTimeout(() => {
        this.isShow = true;
        this.isLand = false;
      }, 500)
    }
  },

  mounted(){
    this.$bus.$on('scrollTop', this.handleScrollTop);
  },

  destroyed() {
    this.$bus.$off('scrollTop', this.handleScrollTop);
  },

  components: {
    GoHome,
    BlogList,
    // ToTop,
    Rocket
  }
}
</script>

<style lang="less" scoped>
.bloglist-contain{
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
}
</style>
